import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

interface TopSellersProps {
  data: any;
}

const TopSellers: React.FC<TopSellersProps> = ({ data }) => {
  return (
    <>
      <section className={styles.sellersection}>
        <div className="container-fluid">
          <div className={styles.sellerinner}>
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.sellerheading}>
                  <h2>{data?.title}</h2>
                </div>
              </div>
            </div>
            <div className={styles.sellercontent}>
              <div className="sellerslider best-seller-slider">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={12}
                  navigation={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 12,
                    },
                    1300: {
                      slidesPerView: 4,
                      spaceBetween: 12,
                    },
                  }}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {data?.items?.map((seller: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Link href={seller.href} className={styles.sellercard}>
                        <div className={styles.sellerimg}>
                          <Image
                            className="lazy loaded"
                            src={seller.imgSrc}
                            alt={seller.alt}
                            title={seller.title}
                            width={500}
                            height={500}
                            data-was-processed="true"
                          />
                          <span className={styles.bestbtnimg}>
                            {seller.saleStatus}
                          </span>
                        </div>
                        <div className={styles.sellertext}>
                          <p>{seller.productName}</p>
                          <h4>
                            {seller.price}{' '}
                            <del className={styles.offprice}>
                              {seller.originalPrice}
                            </del>
                            <span className={styles.discountoff}>
                              {seller.discount}
                            </span>
                          </h4>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopSellers;
